<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="ouvidorias"
      item-key="name"
      class="elevation-1"
      :search="search"
      :loading="loading"
    >
      <template v-slot:[`item.resultado`]="{ item }" >
        <v-chip
          class="white--text"
          label
          outlined
          :color="item.resultado.cor"
        >
          {{item.resultado.text}}
        </v-chip>
      </template>
      <template v-slot:top>
        <v-row class="ma-auto">
          <v-col>
            filtro
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              label="Pesquisar"
              class="mx-0"
            ></v-text-field>
          </v-col>
          <v-col cols="1" class="text-right">
            <v-btn 
                outlined 
                icon
                @click="listar"
            >
              <v-icon>mdi-update</v-icon>
            </v-btn>
        </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <Registro :current-item="item"/>
      </template>     
      <template v-slot:[`item.edit`]="{ item }">
        <Editar :current-item="item"/>
      </template>     
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name:'Administrador',
  components:{
    Registro:()=> import('./Registro'),
    Editar:()=> import('./Edit')
  },
  data:()=>({
    search: '',
    headers: [
      { text: 'Nª reg.',align: 'start',sortable: true, value: 'id',},
      { text: 'Tipo',align: 'start',sortable: true, value: 'tipo.text',},
      { text: 'Colaborador',align: 'start',sortable: true, value: 'usuario.text',},
      { text: 'Setor',align: 'start',sortable: true, value: 'setor.name',},
      { text: 'Data',align: 'start',sortable: false, value: 'data',},
      { text: 'Hora',align: 'start',sortable: false, value: 'hora',},
      { text: 'Resultado',align: 'start',sortable: false, value: 'resultado.text',},
      { text: 'Ver',align: 'center',sortable: false, value: 'actions'},
      { text: 'Editar',align: 'center',sortable: false, value: 'edit'},
    ]
  }),
  
  computed:{
    ...mapState(['loading','lista_sugestoes']),
    ...mapGetters(['ouvidorias']),
  },
  methods:{
    listar(){
      this.$store.dispatch('ouvidorias',{
        method: 'get',
        url: 'informe/ouvidorias/'
      })
      this.$store.dispatch('btnLoading',false)
    },
  },
  mounted(){
    this.listar()
  }
}
</script>